<template>
	<div class="row mt-3">
		<label class="col-sm-4 col-form-label" for="model_label">
			{{ $t('monte.modele_contrat') }} *
			<span class="float-right" v-if="contractconfig_id">
				<router-link :to="{ name: 'ContractConfigForm', params: { contractconfig_id: contractconfig_id }}">
					<font-awesome-icon :icon="['fal', 'link']" />
				</router-link>
			</span>
		</label>
		<div class="col-sm-8">
			<e-select
				:disabled="disabled"
				v-model="modele"
				id="model_id"
				track-by="model_id"
				label="model_label"
				:placeholder="$t('monte.rechercher_modele')"
				:selectedLabel="$t('global.selected_label')"
				:options="modeles"
				:allow-empty="false"
				:show-labels="false"
				:loading="loadingModele"
				:class="{ 'is-invalid': errors && errors.indexOf('modele') > -1 }"
			></e-select>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ContractMixin from "@/mixins/Contract.js"
	import ModelMixin from "@/mixins/Model.js"

	export default {
		name: 'Modele',
		mixins: [ContractMixin, ModelMixin],
		props:{
			contractconfig_id:{
				type: Number,
				default: () => ( 0 )
			},
			value:{
				type: Object,
				default: () => ( null )
			},
			errors: {
				type: Array,
				default: () => ( [] )
			},
			disabled:{
				type: Boolean,
				default: () => ( false )
			},
			avenant_model:{
				type: [Number, String],
				default: () => ( 0 )
			},
		},
		data () {
			return {
                modeles: [],
                loadingModele: false,
			}
		},

		computed: {
			modele: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				await this.loadModeles()
			},

			async loadModeles() {

				this.modele = null
				this.modeles = []
				
				if(this.contractconfig_id != 0 && this.contractconfig_id != null) {
					this.loadingModele = true
					this.modeles = await this.loadAllModel(this.contractconfig_id, ["contract_config"])

					if(this.avenant_model != 0) {
						this.modele = this.modeles.find(model => model.model_id == this.avenant_model)
					}
					else {
						this.modele = this.modeles[0]
					}
					this.loadingModele = false
				}
			}
		},
		watch: {
			contractconfig_id(){
				//Je vais définir le nouveau contract_config puisque l'étalon a changé
				this.loadModeles()
			}
		}
	}

</script>